import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes, Enums, contractScheduleStatusEnum } from "../../constants";
import { Breadcrumb, Button, InputGroup, Badge } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Export from "../../components/common/Export";
import NewLoader from "../../components/loader/NewLoader";

export default function Attendance() {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [list, setList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [txtSearch, setTxtSearch] = useState("");
  const [selectedGuardList, setSelectedGuardList] = useState([]);
  const [guardList, setGuardList] = useState([]);

  // React.useEffect(() => {
  //   getDataList();
  // }, [pageNo, perPageRecords]);

  useEffect(() => {
    getDataList("", "", 1);
  }, [txtSearch, pageNo, perPageRecords]);

  async function getDataList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);

    let newSelectedArray = [];
    selectedGuardList.forEach((property_item) => {
      newSelectedArray = [...newSelectedArray, property_item.value];
    });

    setList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.guardAttendanceReport, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          from != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(from) + " 00:00:00"
              )
            : null,
        end_date:
          to != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(to) + " 23:59:59"
              )
            : null,
        guard_list: newSelectedArray,
      },
    });
    if (requestMetadata) {
      setList({ isData: true, data: requestMetadata.data.list });
      setTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {    
    getDataList(column.sortField, sortDirection, 1);
  };

  const fetchGuardList = async () => {
    let res = await APICall(ApiRoutes.fetchGuardList);
    if (res.status === 1) {
      setGuardList(res.data.guard_list);
    }
  };

  useEffect(() => {
    fetchGuardList();
  }, []);

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (to || from) {
      if (to && from) {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getDataList("", "", 1);
      setTotalRows(1);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setSelectedGuardList([]);
    setTo(null);
    setFrom(null);
    setTxtSearch("");
    getDataList("", "", 0);
  };

  let key_headers = {
    schedule_date: {
      text: "Schedule Date",
      is_date: true,
      date_format: "yyyy-MM-DD",
    },
    property_name: {
      text: "Property Name",
    },
    // "client_name": {
    //     'text': "Property Manager",
    //     'is_date': false
    // },
    guard_name: {
      text: "Guard Name",
      is_date: false,
    },
    clock_in_time: {
      text: "Clock In",
      is_date: true,
      date_format: "HH:mm",
      is_time: true,
    },
    clock_out_time: {
      text: "Clock Out",
      is_date: true,
      date_format: "HH:mm",
      is_time: true,
    },
    final_total_hours: {
      text: "Total Work Hrs",
      is_date: false,
    },
    is_leave: {
      text: "Leave",
      is_date: false,
    },
    reason: {
      text: "Leave Reason",
      is_date: false,
    },
    is_proxy: {
      text: "Proxy",
      is_date: false,
    },
    dummy_review: {
      text: "Remark",
      is_date: false,
    },
  };

  const columns = [
    {
      name: "Schedule Date",
      selector: (row) => commonService.convertUTCToLocal(row.schedule_date),
      sortField: "schedule_date",
      sortable: true,
    },
    {
      name: "Property Name",
      selector: (row) => row.property_name,
      sortField: "property_name",
      sortable: true,
    },
    // {
    //     name: "Property Manager",
    //     selector: (row) => row.client_name,
    //     sortField: "client_name",
    //     sortable: true,
    // },
    {
      name: "Guard Name",
      selector: (row) => row.guard_name,
      sortField: "guard_name",
      sortable: true,
    },
    {
      name: "Clock In",
      selector: (row) =>
        row.clock_in_time
          ? commonService.convertUtcTimeToLocalTime(
              row.clock_in_time,
              "HH:mm",
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
      sortField: "schedule_start_time",
      sortable: true,
    },
    {
      name: "Clock out",
      selector: (row) =>
        row.clock_out_time
          ? commonService.convertUtcTimeToLocalTime(
              row.clock_out_time,
              "HH:mm",
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
      sortField: "schedule_end_time",
      sortable: true,
    },
    {
      name: "Total Work Hrs",
      selector: (row) =>
        row.final_total_hours !== "" && row.final_total_hours !== null
          ? commonService.TimestampConverter(row.final_total_hours)
          : "",
      // sortField: "schedule_end_time",
      sortable: true,
    },
    {
      name: "Leave",
      selector: (row) =>
        row.is_leave ? <i className="fa fa-check" aria-hidden="true"></i> : "",
      sortable: true,
    },
    {
      name: "Leave Reason",
      selector: (row) => row.reason,
      sortField: "reason",
      sortable: true,
    },
    {
      name: "Proxy",

      selector: (row) =>
        parseInt(row.is_proxy) == 1 ? (
          <i class="fa fa-flag" aria-hidden="true"></i>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Remark",
      selector: "",
      sortable: false,
    },
  ];

  const TableExpander = (props) => {
    let TimeArray = props?.data?.clockin_info;
    if (props.data.clockin_info.length) {
      return (
        <Card>
          <Card.Header className="p-2">Attendance history</Card.Header>
          <Card.Body className="p-2">
            <ul className="timeline ms-1">
              <li className="timeline-item">
                <span className="timeline-point timeline-point-indicator"></span>
                <div className="timeline-event">
                  <div className="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>
                      {props.data.clock_in_time
                        ? commonService.convertUtcTimeToLocalTime(
                            props.data.clock_in_time,
                            "MM-DD-YYYY HH:mm",
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "N/A"}
                      -{" "}
                      {props.data.clock_out_time
                        ? commonService.convertUtcTimeToLocalTime(
                            props.data.clock_out_time,
                            "MM-DD-YYYY HH:mm",
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "N/A"}
                    </h6>
                    {/* <span className="timeline-event-time h6 text-dark">
                          {data.sc_checkpoint_history.visited_checkpoints_count}{" "}
                          / {data.sc_checkpoint_history.total_checkpoints_count}
                        </span> */}
                  </div>
                  <p className="login-time mb-1">Attendance Summary</p>
                  <div className="mt-3">
                    <div>
                      <div
                        style={{ paddingRight: "0.5rem" }}
                        className="d-flex justify-content-between h6 mb-0 align-item-center"
                      >
                        <div className="h6">Clock In Time</div>
                        <div className="h6">Clock Out Time</div>
                      </div>
                    </div>
                    <ul className="mt-2 p-0">
                      {TimeArray?.map((data, index) => {
                        return (
                          <li className="p-2 " key={index}>
                            <div className="d-flex justify-content-between mb-0 align-item-center">
                              {/* <div className="h6">
                                        {sc_checkpoint_history.title}
                                      </div> */}
                              <div>
                                {data.clock_in_time
                                  ? commonService.convertUTCToLocal(
                                      data.clock_in_time,
                                      " hh:mm A"
                                    )
                                  : "-"}
                              </div>
                              <div>
                                {data.clock_out_time
                                  ? commonService.convertUTCToLocal(
                                      data.clock_out_time,
                                      " hh:mm A"
                                    )
                                  : "-"}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>{" "}
                  </div>
                </div>
              </li>
              {/* ); */}
              {/* })} */}
            </ul>
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <div className="text-center m-4 h6">Attendance history not found</div>
      );
    }
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Attendance List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/attendance_report">
                  Reports
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Guard Attendance</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="row">
                <div className="col-3">
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={from ? from : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setFrom(date);
                        } else {
                          setFrom(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-3">
                  <div>
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={to ? to : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setTo(date);
                          } else {
                            setTo(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="col-6">
                  <DropDown
                    style={{ div: "col-12" }}
                    value={selectedGuardList}
                    data={guardList}
                    onChange={(value) => setSelectedGuardList(value)}
                    isMulti={true}
                    placeholder="Select Guard"
                    required
                    // validationText={formInputsErrors.duration_term}
                  />
                </div>

                <div className="col-3">
                  <div className="d-flex">
                    <div className="me-2">
                      <Button
                        onClick={searchDateWise}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="ms-">
                      <Button
                        onClick={resetFilter}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-refresh"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="my-tabel-main-one my-tabel-main z-adjust-for-dropdown custom-th-ga-width hideDiv my-special-class">
        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={columns}
          data={list.data}
          progressPending={dataTableLoading}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          expandableRowsComponent={TableExpander}
          expandableRows={true}
          paginationTotalRows={totalRows}
          expandableRowDisabled={(row) =>
            row.staff_type === Enums.GuardTypes.gate_guard
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          paginationPerPage={perPageRecords}
          actions={
            <>
              <div className="col-4">
                <InputText
                  formGroupClass={"mb-0"}
                  size={"12"}
                  type={"text"}
                  Label={false}
                  placeholder={"Search..."}
                  value={txtSearch}
                  onChange={(value) => {
                    if (value) {
                      setTxtSearch(value);
                    } else {
                      setTxtSearch(value);
                    }
                  }}
                />
              </div>
              <Export
                data={list.data}
                key_headers={key_headers}
                name={"Guard Attendance"}
              />
            </>
          }
        />
      </div>
    </>
  );
}
